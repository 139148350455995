import { createGtagEvent } from '@/components/Google/GoogleAnalyticsScript';
import { LandingPageServerSideProps } from '@/pages';
import { AspectRatio } from '@/shadcn-ui/components/ui/aspect-ratio';
import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/shadcn-ui/components/ui/form';
import { Input } from '@/shadcn-ui/components/ui/input';
import { ShineBorder } from '@/shadcn-ui/components/ui/shine-border';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { CustomersLogos } from '../CustomersLogos';

const FormSchema = z.object({
  email: z.string(),
});

interface HeroProps extends LandingPageServerSideProps {
  title: string;
  titleGradient: string;
  subtitle: string;
  cta: string;
  imageUrl: string;
}

export function HeroWithTwoImages({
  title,
  titleGradient,
  subtitle,
  cta,
  imageUrl,
  utm_source,
  utm_medium,
  utm_campaign,
  utm_content,
}: HeroProps) {
  const { push } = useRouter();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log({ utm_source, utm_medium, utm_campaign, utm_content });

    if (data.email) {
      createGtagEvent('event', 'generate_lead');
    }

    push(
      {
        pathname: '/trial',
        query: {
          email: data.email,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
        },
      },
      undefined,
      { shallow: true },
    );
  }

  const { locale } = useRouter();

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="flex pt-10 flex-col w-full max-w-7xl gap-8">
        <div
          className={`w-full h-full flex flex-col md:flex-row items-center px-4 gap-8`}
        >
          <div className="w-full sm:w-1/2 h-full flex flex-col items-start gap-8">
            <div className="flex flex-col sm:gap-8 gap-4">
              <div className="sm:text-5xl text-4xl font-bold text-gray-700">
                {title}&nbsp;
                <span className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF] text-transparent bg-clip-text">
                  {titleGradient}
                </span>
              </div>
              <p className="sm:text-2xl text-xl text-gray-500">{subtitle}</p>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className=" items-center gap-4 hidden md:flex"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <ShineBorder
                          className="text-center text-2xl capitalize p-0 shadow-md h-12 w-full"
                          color={['#7903EF', '#1C45D6']}
                        >
                          <Input
                            className="h-11 border-[0px] text-lg w-full "
                            type="email"
                            placeholder={
                              locale === 'pt'
                                ? 'E-mail profissional'
                                : 'Work e-mail'
                            }
                            {...field}
                          />
                        </ShineBorder>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <ShadCnButton
                  className="text-lg h-12 shadow-lg w-full"
                  variant="adstart"
                >
                  {cta}
                </ShadCnButton>
              </form>
            </Form>
          </div>
          <div className="relative w-full sm:w-1/2 h-fit flex items-center justify-center">
            <AspectRatio ratio={3 / 2}>
              <img width={618} height={420} src={imageUrl} alt={imageUrl} />
            </AspectRatio>

            <img
              className="top-10 right-0 absolute rounded-md object-cover hidden md:block"
              src={'/iphone-mockup.png'}
              alt={'/iphone-mockup.png'}
              width={170}
            />

            <img
              className="bottom-5 right-0 absolute rounded-md object-cover md:hidden"
              src={'/iphone-mockup.png'}
              alt={'/iphone-mockup.png'}
              width={100}
            />
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4 items-center justify-center md:hidden w-full"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <ShineBorder
                        className="text-center text-2xl capitalize p-0 shadow-md h-12 w-full"
                        color={['#7903EF', '#1C45D6']}
                      >
                        <Input
                          className="h-11 border-[0px] text-lg w-full"
                          placeholder={
                            locale === 'pt'
                              ? 'E-mail profissional'
                              : 'Work e-mail'
                          }
                          type="email"
                          {...field}
                        />
                      </ShineBorder>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <ShadCnButton
                className="text-lg h-12 shadow-lg w-full"
                variant="adstart"
              >
                {cta}
              </ShadCnButton>
            </form>
          </Form>
        </div>
        <CustomersLogos isAdstart={true} />
      </div>
    </div>
  );
}
